import { useState } from "react";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = () => {
        fetch("/auth/token", {
            "method": "POST",
            "body": JSON.stringify({username, password}),
            "headers": {
                "Content-Type": "application/json",
            }
        }).then((resp) => resp.json()).then((resp) => {
            if (resp.token) {
                window.localStorage.setItem("token", resp.token);
                navigate("/");
            }
        });
    };

    return (
        <div className="w-80 mt-40 mb-40 ml-auto mr-auto text-center">
            <Input className="mt-1 mb-1" type="text" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
            <Input className="mt-1 mb-1" type="password" placeholder="******" value={password} onChange={(event) => setPassword(event.target.value)} />
            <Button onClick={handleLogin}>Login</Button>
        </div>
    )
};

export default LoginPage;
