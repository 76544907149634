import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import {
    Card,
    CardContent,
    CardHeader,
} from "./card";

const ImageCard = ({ idx, imageUrl, comment, onOpenFullImage }) => {
    const handleOpenFullImage = () => {
        onOpenFullImage(idx);
    };

    return (
        <>
            <Card>
                <CardHeader style={{textAlign: "center"}}>{comment}</CardHeader>
                <CardContent style={{cursor: "pointer"}} onClick={handleOpenFullImage}>
                    <Avatar style={{width: "fit-content", height: "auto"}}>
                        <AvatarImage src={imageUrl} style={{objectFit: "cover"}} />
                        <AvatarFallback>Loading...</AvatarFallback>
                    </Avatar>
                </CardContent>
            </Card>
        </>
    );
};

export default ImageCard;
