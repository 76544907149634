import "./index.css";

import React from 'react';
import ReactDOM from 'react-dom/client';

import { HashRouter, Route, Routes } from 'react-router-dom';
import GalleryPage from "./pages/gallery";
import LoginPage from "./pages/login";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/login" name="login" element={<LoginPage />} />
      <Route path="/" name="index" element={<GalleryPage />} />
    </Routes>
  </HashRouter>
);
