import { useEffect, useState } from "react";
import ImageCard from "../components/image-card";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "../components/dialog";

const GalleryPage = () => {
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [openImageIdx, setOpenImageIdx] = useState(null);

    useEffect(() => {
        const loginToken = window.localStorage.getItem("token");

        if (!loginToken) {
            navigate("/login");
        }

        fetch("/api/gallery", {headers: {
            "Authorization": `Token ${loginToken}`,
            "Content-Type": "application/json"
        }}).then((resp) => resp.json()).then((resp) => {
            setImages(resp.items || []);
        }).catch(err => {
            navigate("/#/login");
        });
    }, []);

    const handleOpenFullImage = (idx) => {
        setOpenImageIdx(idx);
    };

    const imageCards = images.map((image, idx) => {
        return (
            <ImageCard idx={idx} imageUrl={image.image_url} comment={image.comment || ""} onOpenFullImage={handleOpenFullImage} />
        );
    });

    let fullImage = null;
    if (openImageIdx) {
        fullImage = images[openImageIdx];
    }

    return (
        <div>
            <div>
                <img src="/design-2.png" alt="#BaniRanjanKi" className="w-[100%] h-20 mt-0 mb-0 mr-auto ml-auto" />
            </div>

            <div className="grid grid-cols-4 gap-4 p-4">
                {imageCards}
            </div>

            <p className="mt-4 mb-4 ml-8 mr-8 text-center text-2xl">
                Congratulations Bani & Ranjan! Had lots of fun at the wedding.
                Well, I really wanted to automate sending the lifafa, but Fintech industry hasn't evolved to the level it was required to attempt this.
                Anyway, Sachin will be sending you your shadi ka lifafa soon. It won't be like the usual lifafa, but the contents are real!
            </p>

            <Dialog open={openImageIdx !== null} onOpenChange={(open) => setOpenImageIdx(null)}>
                <DialogContent>
                    <img src={fullImage ? fullImage.image_url : ""} alt={"Loading..."} />
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default GalleryPage;
